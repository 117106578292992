import {
  CHECK_PREVIOUS_DAY,
  CHECK_PREVIOUS_DAY_FAILURE,
  CHECK_PREVIOUS_DAY_REQUEST,
  EXERCISE_DETAIL_FAILURE,
  EXERCISE_DETAIL_REQUEST,
  EXERCISE_DETAIL_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_FAILURE,
  GET_QUESTION_REQUEST,
  GET_TEST,
  GET_TEST_FAILURE,
  GET_TEST_REQUEST,
  GET_TESTS,
  GET_TESTS_FAILURE,
  GET_TESTS_REQUEST,
  GET_USER_PROGRAM,
  GET_USER_PROGRAM_DAY,
  GET_USER_PROGRAM_DAY_FAILURE,
  GET_USER_PROGRAM_DAY_REQUEST,
  SUBMIT_CHECKIN_FEEDBACK,
  SUBMIT_CHECKIN_FEEDBACK_FAILURE,
  SUBMIT_CHECKIN_FEEDBACK_REQUEST,
  USER_PROGRAM_REQUEST,
  USER_PROGRAM_REQUEST_FAILURE,
  WEEK_SCHEDULE_FAILURE,
  WEEK_SCHEDULE_REQUEST,
  WEEK_SCHEDULE_SUCCESS,
  RECOMMENDED_PRODUCTS_SUCCESS,
  RECOMMENDED_PRODUCTS_REQUEST,
  RECOMMENDED_PRODUCTS_FAILURE,
  TESTIMONIALS_SUCCESS,
  TESTIMONIALS_REQUEST,
  TESTIMONIALS_FAILURE,
  EDUCATIONAL_VIDEOS_REQUEST,
  EDUCATIONAL_VIDEOS_SUCCESS,
  EDUCATIONAL_VIDEOS_FAILURE,
  EXERCISE_LEVELS_REQUEST,
  EXERCISE_LEVELS_SUCCESS,
  EXERCISE_LEVELS_FAILURE,
  LEVEL_CHANGE_REQUEST,
  LEVEL_CHANGE_SUCCESS,
  LEVEL_CHANGE_FAILURE,
  LEVEL_CHANGE_PURGE,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAILURE,
  USER_PROGRAM_EXERCISE_UPDATE_PURGE,
  USER_PROGRAM_EXERCISE_UPDATE_FAILURE,
  USER_PROGRAM_EXERCISE_UPDATE_SUCCESS,
  USER_PROGRAM_EXERCISE_UPDATE_REQUEST,
  CHECK_PREVIOUS_DAY_PURGE,
  SUBMIT_CHECKIN_FEEDBACK_PURGE,
  SUBMIT_CHECKOUT_FEEDBACK_PURGE,
  SUBMIT_CHECKOUT_FEEDBACK_FAILURE,
  SUBMIT_CHECKOUT_FEEDBACK,
  SUBMIT_CHECKOUT_FEEDBACK_REQUEST,
  USER_PROGRAM_UPDATE_PURGE,
  USER_PROGRAM_UPDATE_FAILURE,
  USER_PROGRAM_UPDATE_SUCCESS,
  USER_PROGRAM_UPDATE_REQUEST,
  USER_SKIP_EXERCISE_COUNT_PURGE,
  USER_SKIP_EXERCISE_COUNT_FAILURE,
  USER_SKIP_EXERCISE_COUNT_SUCCESS,
  USER_SKIP_EXERCISE_COUNT_REQUEST,
  EXERCISE_SUMMARY_REQUEST,
  EXERCISE_SUMMARY_SUCCESS,
  EXERCISE_SUMMARY_FAILURE,
  UPDATE_PROGRAM_STATUS_REQUEST,
  UPDATE_PROGRAM_STATUS_SUCCESS,
  UPDATE_PROGRAM_STATUS_FAILURE,
  UPDATE_PROGRAM_STATUS_PURGE,
  APT_VIDEOS_REQUEST,
  APT_VIDEOS_SUCCESS,
  APT_VIDEOS_FAILURE,
  FAQS_REQUEST,
  FAQS_SUCCESS,
  FAQS_FAILURE,
  MUSCLES_ANATOMY_FAILURE,
  MUSCLES_ANATOMY_SUCCESS,
  MUSCLES_ANATOMY_REQUEST,
  PAIN_POINT_REQUEST,
  PAIN_POINT_SUCCESS,
  PAIN_POINT_FAILURE,
  MUSCLES_CATEGORIES_REQUEST,
  MUSCLES_CATEGORIES_SUCCESS,
  MUSCLES_ANATOMY_DETAIL_REQUEST,
  MUSCLES_ANATOMY_DETAIL_SUCCESS,
  MUSCLES_ANATOMY_DETAIL_FAILURE,
  PAIN_POINT_DETAIL_REQUEST,
  PAIN_POINT_DETAIL_SUCCESS,
  PAIN_POINT_DETAIL_FAILURE,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  RECOMMENDED_PRODUCTS_CATEGORIES_REQUEST,
  RECOMMENDED_PRODUCTS_CATEGORIES_SUCCESS,
  RECOMMENDED_PRODUCTS_CATEGORIES_FAILURE,
} from "../actions/types";

const initialState = {
  program: null,
  loading: false,
  weekSchedule: null,
  programId: null,
  exerciseDetail: null,
  exercises: null,
  submitCheckInSuccess: false,
  exercisesLevels: null,
  levelUpdate: null,
  recommandedProductCategories: null,
};

function programReducer(state = initialState, action) {
  const { type, payload } = action;
  console.log("action", action);

  switch (type) {
    case USER_PROGRAM_REQUEST:
    case WEEK_SCHEDULE_REQUEST:
      return {
        ...state,
        weekScheduleRequest: true,
        weekScheduleSuccess: false,
        weekScheduleFailure: false,
      };
    case GET_USER_PROGRAM:
      localStorage.setItem("program", JSON.stringify(payload?.program));
      return {
        ...state,
        program: payload?.program,
        programId: payload?.id,
        loading: false,
        userHasProgram: true,
      };
    case USER_PROGRAM_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        program: null,
        programId: null,
        userHasProgram: false,
      };
    case WEEK_SCHEDULE_SUCCESS:
      return {
        ...state,
        weekSchedule: payload,
        weekScheduleRequest: false,
        weekScheduleSuccess: true,
        weekScheduleFailure: false,
      };
    case WEEK_SCHEDULE_FAILURE:
      return {
        ...state,
        weekScheduleRequest: false,
        weekScheduleSuccess: false,
        weekScheduleFailure: true,
      };
    case EXERCISE_DETAIL_REQUEST:
      return {
        ...state,
        exerciseDetailRequest: true,
        exerciseDetailSuccess: false,
        exerciseDetailFailure: false,
        exercises: null,
      };
    case EXERCISE_DETAIL_SUCCESS:
      return {
        ...state,
        exerciseDetailRequest: false,
        exerciseDetailSuccess: true,
        exerciseDetailFailure: false,
        exercises: payload,
      };
    case EXERCISE_DETAIL_FAILURE:
      return {
        ...state,
        exerciseDetailRequest: false,
        exerciseDetailSuccess: false,
        exerciseDetailFailure: true,
      };
    case EXERCISE_LEVELS_REQUEST:
      return {
        ...state,
        exerciseLevelsRequest: true,
        exerciseLevelsSuccess: false,
        exerciseLevelsFailure: false,
        exercisesLevels: null,
      };
    case EXERCISE_LEVELS_SUCCESS:
      return {
        ...state,
        exerciseLevelsRequest: false,
        exerciseLevelsSuccess: true,
        exerciseLevelsFailure: false,
        exercisesLevels: payload,
      };
    case EXERCISE_LEVELS_FAILURE:
      return {
        ...state,
        exerciseLevelsRequest: false,
        exerciseLevelsSuccess: false,
        exerciseLevelsFailure: true,
      };
    case LEVEL_CHANGE_REQUEST:
      return {
        ...state,
        levelUpdateRequest: true,
        levelUpdateSuccess: false,
        levelUpdateFailure: false,
        levelUpdate: null,
      };
    case LEVEL_CHANGE_SUCCESS:
      return {
        ...state,
        levelUpdateRequest: false,
        levelUpdateSuccess: true,
        levelUpdateFailure: false,
        levelUpdate: payload,
      };
    case LEVEL_CHANGE_FAILURE:
      return {
        ...state,
        levelUpdateRequest: false,
        levelUpdateSuccess: false,
        levelUpdateFailure: true,
      };
    case LEVEL_CHANGE_PURGE:
      return {
        ...state,
        levelUpdateRequest: false,
        levelUpdateSuccess: false,
        levelUpdateFailure: false,
        levelUpdate: null,
      };
    case GET_TESTS_REQUEST:
      return {
        ...state,
        getTestsRequest: true,
        getTestsSuccess: false,
        getTestsFailure: false,
        tests: null,
      };
    case GET_TESTS:
      return {
        ...state,
        getTestsRequest: false,
        getTestsSuccess: true,
        getTestsFailure: false,
        program_tests: payload,
      };
    case GET_TESTS_FAILURE:
      return {
        ...state,
        getTestsRequest: false,
        getTestsSuccess: false,
        getTestsFailure: true,
      };
    case GET_TEST_REQUEST:
      return {
        ...state,
        getTestRequest: true,
        getTestSuccess: false,
        getTestFailure: false,
        testDetails: null,
      };
    case GET_TEST:
      return {
        ...state,
        getTestRequest: false,
        getTestSuccess: true,
        getTestFailure: false,
        testDetails: payload,
      };
    case GET_TEST_FAILURE:
      return {
        ...state,
        getTestRequest: false,
        getTestSuccess: false,
        getTestFailure: true,
      };
    case GET_USER_PROGRAM_DAY_REQUEST:
      return {
        ...state,
        getTestRequest: true,
        getTestSuccess: false,
        getTestFailure: false,
        user_program_day: null,
      };
    case GET_USER_PROGRAM_DAY:
      return {
        ...state,
        getTestRequest: false,
        getTestSuccess: true,
        getTestFailure: false,
        user_program_day: payload,
      };
    case GET_USER_PROGRAM_DAY_FAILURE:
      return {
        ...state,
        getTestRequest: false,
        getTestSuccess: false,
        getTestFailure: true,
      };
    case GET_QUESTION_REQUEST:
      return {
        ...state,
        getQuestionRequest: true,
        getQuestionSuccess: false,
        getQuestionFailure: false,
        questionDetails: null,
      };
    case GET_QUESTION:
      return {
        ...state,
        getQuestionRequest: false,
        getQuestionSuccess: true,
        getQuestionFailure: false,
        questionDetails: payload,
      };
    case GET_QUESTION_FAILURE:
      return {
        ...state,
        getQuestionRequest: false,
        getQuestionSuccess: false,
        getQuestionFailure: true,
      };
    case CHECK_PREVIOUS_DAY_REQUEST:
      return {
        ...state,
        checkPreviousDayRequest: true,
        checkPreviousDaySuccess: false,
        checkPreviousDayFailure: false,
        previous_day: null,
      };
    case CHECK_PREVIOUS_DAY:
      return {
        ...state,
        checkPreviousDayRequest: false,
        checkPreviousDaySuccess: true,
        checkPreviousDayFailure: false,
        previous_day: payload,
      };
    case CHECK_PREVIOUS_DAY_FAILURE:
      return {
        ...state,
        checkPreviousDayRequest: false,
        checkPreviousDaySuccess: false,
        checkPreviousDayFailure: true,
        previous_day: null,
      };
    case CHECK_PREVIOUS_DAY_PURGE:
      return {
        ...state,
        checkPreviousDayRequest: false,
        checkPreviousDaySuccess: false,
        checkPreviousDayFailure: false,
        previous_day: null,
      };
    case SUBMIT_CHECKIN_FEEDBACK_REQUEST:
      return {
        ...state,
        submitCheckInRequest: true,
        submitCheckInSuccess: false,
        submitCheckInFailure: false,
      };
    case SUBMIT_CHECKIN_FEEDBACK:
      return {
        ...state,
        submitCheckInRequest: false,
        submitCheckInSuccess: true,
        submitCheckInFailure: false,
      };
    case SUBMIT_CHECKIN_FEEDBACK_FAILURE:
      return {
        ...state,
        submitCheckInRequest: false,
        submitCheckInSuccess: false,
        submitCheckInFailure: true,
      };
    case SUBMIT_CHECKIN_FEEDBACK_PURGE:
      return {
        ...state,
        submitCheckInRequest: false,
        submitCheckInSuccess: false,
        submitCheckInFailure: false,
      };

    case SUBMIT_CHECKOUT_FEEDBACK_REQUEST:
      return {
        ...state,
        submitCheckOutRequest: true,
        submitCheckOutSuccess: false,
        submitCheckOutFailure: false,
      };
    case SUBMIT_CHECKOUT_FEEDBACK:
      return {
        ...state,
        submitCheckOutRequest: false,
        submitCheckOutSuccess: true,
        submitCheckOutFailure: false,
      };
    case SUBMIT_CHECKOUT_FEEDBACK_FAILURE:
      return {
        ...state,
        submitCheckOutRequest: false,
        submitCheckOutSuccess: false,
        submitCheckOutFailure: true,
      };
    case SUBMIT_CHECKOUT_FEEDBACK_PURGE:
      return {
        ...state,
        submitCheckOutRequest: false,
        submitCheckOutSuccess: false,
        submitCheckOutFailure: false,
      };

    case RECOMMENDED_PRODUCTS_REQUEST:
      return {
        ...state,
        RecommendedProductsRequest: true,
        RecommendedProductsSuccess: false,
        RecommendedProductsFailure: false,
        products: null,
      };
    case RECOMMENDED_PRODUCTS_SUCCESS:
      return {
        ...state,
        RecommendedProductsRequest: false,
        RecommendedProductsSuccess: true,
        RecommendedProductsFailure: false,
        products: payload,
      };
    case RECOMMENDED_PRODUCTS_FAILURE:
      return {
        ...state,
        RecommendedProductsRequest: false,
        RecommendedProductsSuccess: false,
        RecommendedProductsFailure: true,
      };

    case CATEGORIES_REQUEST:
      return {
        ...state,
        RecommendedProductsRequest: true,
        RecommendedProductsSuccess: false,
        RecommendedProductsFailure: false,
        categories: null,
      };
    case CATEGORIES_SUCCESS:
      return {
        ...state,
        RecommendedProductsRequest: false,
        RecommendedProductsSuccess: true,
        RecommendedProductsFailure: false,
        categories: payload,
      };
    case MUSCLES_CATEGORIES_REQUEST:
      return {
        ...state,
        RecommendedProductsRequest: true,
        RecommendedProductsSuccess: false,
        RecommendedProductsFailure: false,
        musclesCategories: null,
      };
    case MUSCLES_CATEGORIES_SUCCESS:
      return {
        ...state,
        RecommendedProductsRequest: false,
        RecommendedProductsSuccess: true,
        RecommendedProductsFailure: false,
        musclesCategories: payload,
      };

    case TESTIMONIALS_REQUEST:
      return {
        ...state,
        TestimonialsRequest: true,
        TestimonialsSuccess: false,
        TestimonialsFailure: false,
        testimonial: null,
      };
    case TESTIMONIALS_SUCCESS:
      return {
        ...state,
        TestimonialsRequest: false,
        TestimonialsSuccess: true,
        TestimonialsFailure: false,
        testimonial: payload,
      };
    case TESTIMONIALS_FAILURE:
      return {
        ...state,
        TestimonialsRequest: false,
        TestimonialsSuccess: false,
        TestimonialsFailure: true,
      };
    case FAQS_REQUEST:
      return {
        ...state,
        FaqsRequest: true,
        FaqsSuccess: false,
        FaqsFailure: false,
        faqs: null,
      };
    case FAQS_SUCCESS:
      return {
        ...state,
        FaqsRequest: false,
        FaqsSuccess: true,
        FaqsFailure: false,
        faqs: payload,
      };
    case FAQS_FAILURE:
      return {
        ...state,
        FaqsRequest: false,
        FaqsSuccess: false,
        FaqsFailure: true,
      };
    case EDUCATIONAL_VIDEOS_REQUEST:
      return {
        ...state,
        EducationVideosRequest: true,
        EducationVideosSuccess: false,
        EducationVideosFailure: false,
        educational_video: null,
      };
    case EDUCATIONAL_VIDEOS_SUCCESS:
      return {
        ...state,
        EducationVideosRequest: false,
        EducationVideosSuccess: true,
        EducationVideosFailure: false,
        educational_video: payload,
      };
    case EDUCATIONAL_VIDEOS_FAILURE:
      return {
        ...state,
        EducationVideosRequest: false,
        EducationVideosSuccess: false,
        EducationVideosFailure: true,
      };
    case MUSCLES_ANATOMY_REQUEST:
      return {
        ...state,
        MusclesAnatomyRequest: true,
        MusclesAnatomySuccess: false,
        MusclesAnatomyFailure: false,
        muscles_anatomy: null,
      };
    case MUSCLES_ANATOMY_SUCCESS:
      return {
        ...state,
        MusclesAnatomyRequest: false,
        MusclesAnatomySuccess: true,
        MusclesAnatomyFailure: false,
        muscles_anatomy: payload,
      };
    case MUSCLES_ANATOMY_FAILURE:
      return {
        ...state,
        MusclesAnatomyRequest: false,
        MusclesAnatomySuccess: false,
        MusclesAnatomyFailure: true,
      };
    case MUSCLES_ANATOMY_DETAIL_REQUEST:
      return {
        ...state,
        MusclesAnatomyDetailRequest: true,
        MusclesAnatomyDetailSuccess: false,
        MusclesAnatomyDetailFailure: false,
        muscles_anatomy_detail: null,
      };
    case MUSCLES_ANATOMY_DETAIL_SUCCESS:
      return {
        ...state,
        MusclesAnatomyDetailRequest: false,
        MusclesAnatomyDetailSuccess: true,
        MusclesAnatomyDetailFailure: false,
        muscles_anatomy_detail: payload,
      };
    case MUSCLES_ANATOMY_DETAIL_FAILURE:
      return {
        ...state,
        MusclesAnatomyDetailRequest: false,
        MusclesAnatomyDetailSuccess: false,
        MusclesAnatomyDetailFailure: true,
      };
    case PAIN_POINT_REQUEST:
      return {
        ...state,
        PainPointRequest: true,
        PainPointSuccess: false,
        PainPointFailure: false,
        pain_point: null,
      };
    case PAIN_POINT_SUCCESS:
      return {
        ...state,
        PainPointRequest: false,
        PainPointSuccess: true,
        PainPointFailure: false,
        pain_point: payload,
      };
    case PAIN_POINT_FAILURE:
      return {
        ...state,
        PainPointRequest: false,
        PainPointSuccess: false,
        pain_point: true,
      };
    case PAIN_POINT_DETAIL_REQUEST:
      return {
        ...state,
        PainPointDetailRequest: true,
        PainPointDetailSuccess: false,
        PainPointDetailFailure: false,
        pain_point_detail: null,
      };
    case PAIN_POINT_DETAIL_SUCCESS:
      return {
        ...state,
        PainPointDetailRequest: false,
        PainPointDetailSuccess: true,
        PainPointDetailFailure: false,
        pain_point_detail: payload,
      };
    case PAIN_POINT_DETAIL_FAILURE:
      return {
        ...state,
        PainPointDetailRequest: false,
        PainPointDetailSuccess: false,
        pain_point_detail: true,
      };
    case APT_VIDEOS_REQUEST:
      return {
        ...state,
        aptVideosRequest: true,
        aptVideosSuccess: false,
        aptVideosFailure: false,
        aptVideos: null,
      };
    case APT_VIDEOS_SUCCESS:
      return {
        ...state,
        aptVideosRequest: false,
        aptVideosSuccess: true,
        aptVideosFailure: false,
        aptVideos: payload,
      };
    case APT_VIDEOS_FAILURE:
      return {
        ...state,
        aptVideosRequest: false,
        aptVideosSuccess: false,
        aptVideosFailure: true,
        aptVideos: null,
      };
    case COUNTRIES_REQUEST:
      return {
        ...state,
        CountriesRequest: true,
        CountriesSuccess: false,
        CountriesFailure: false,
        countries: null,
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        CountriesRequest: false,
        CountriesSuccess: true,
        CountriesFailure: false,
        countries: payload,
      };
    case COUNTRIES_FAILURE:
      return {
        ...state,
        CountriesRequest: false,
        CountriesSuccess: false,
        CountriesFailure: true,
      };

    case USER_PROGRAM_EXERCISE_UPDATE_REQUEST:
      return {
        ...state,
        exerciseUpdateRequest: true,
        exerciseUpdateSuccess: false,
        exerciseUpdateFailure: false,
      };
    case USER_PROGRAM_EXERCISE_UPDATE_SUCCESS:
      return {
        ...state,
        exerciseUpdateRequest: false,
        exerciseUpdateSuccess: true,
        exerciseUpdateFailure: false,
        updatedExercises: payload,
      };
    case USER_PROGRAM_EXERCISE_UPDATE_FAILURE:
      return {
        ...state,
        exerciseUpdateRequest: false,
        exerciseUpdateSuccess: false,
        exerciseUpdateFailure: true,
      };
    case USER_PROGRAM_EXERCISE_UPDATE_PURGE:
      return {
        ...state,
        exerciseUpdateRequest: false,
        exerciseUpdateSuccess: false,
        exerciseUpdateFailure: false,
      };

    case USER_PROGRAM_UPDATE_REQUEST:
      return {
        ...state,
        programDayUpdateRequest: true,
        programDayUpdateSuccess: false,
        programDayUpdateFailure: false,
      };
    case USER_PROGRAM_UPDATE_SUCCESS:
      return {
        ...state,
        programDayUpdateRequest: false,
        programDayUpdateSuccess: true,
        programDayUpdateFailure: false,
      };
    case USER_PROGRAM_UPDATE_FAILURE:
      return {
        ...state,
        programDayUpdateRequest: false,
        programDayUpdateSuccess: false,
        programDayUpdateFailure: true,
      };
    case USER_PROGRAM_UPDATE_PURGE:
      return {
        ...state,
        programDayUpdateRequest: false,
        programDayUpdateSuccess: false,
        programDayUpdateFailure: false,
      };

    case USER_SKIP_EXERCISE_COUNT_REQUEST:
      return {
        ...state,
        skipExerciseCountRequest: true,
        skipExerciseCountSuccess: false,
        skipExerciseCountFailure: false,
        skipExerciseCount: null,
      };
    case USER_SKIP_EXERCISE_COUNT_SUCCESS:
      return {
        ...state,
        skipExerciseCountRequest: false,
        skipExerciseCountSuccess: true,
        skipExerciseCountFailure: false,
        skipExerciseCount: payload,
      };
    case USER_SKIP_EXERCISE_COUNT_FAILURE:
      return {
        ...state,
        skipExerciseCountRequest: false,
        skipExerciseCountSuccess: false,
        skipExerciseCountFailure: true,
        skipExerciseCount: null,
      };
    case USER_SKIP_EXERCISE_COUNT_PURGE:
      return {
        ...state,
        skipExerciseCountRequest: false,
        skipExerciseCountSuccess: false,
        skipExerciseCountFailure: false,
        skipExerciseCount: null,
      };

    case UPDATE_PROGRAM_STATUS_REQUEST:
      return {
        ...state,
        userProgramDayStatusUpdateRequest: true,
        userProgramDayStatusUpdateSuccess: false,
        userProgramDayStatusUpdateFailure: false,
        userProgramDayStatusUpdate: null,
      };
    case UPDATE_PROGRAM_STATUS_SUCCESS:
      return {
        ...state,
        userProgramDayStatusUpdateRequest: false,
        userProgramDayStatusUpdateSuccess: true,
        userProgramDayStatusUpdateFailure: false,
        userProgramDayStatusUpdate: payload,
      };
    case UPDATE_PROGRAM_STATUS_FAILURE:
      return {
        ...state,
        userProgramDayStatusUpdateRequest: false,
        userProgramDayStatusUpdateSuccess: false,
        userProgramDayStatusUpdateFailure: true,
        userProgramDayStatusUpdate: null,
      };
    case UPDATE_PROGRAM_STATUS_PURGE:
      return {
        ...state,
        userProgramDayStatusUpdateRequest: false,
        userProgramDayStatusUpdateSuccess: false,
        userProgramDayStatusUpdateFailure: false,
        userProgramDayStatusUpdate: null,
      };

    case EXERCISE_SUMMARY_REQUEST:
      return {
        ...state,
        exerciseSummaryRequest: true,
        exerciseSummarySuccess: false,
        exerciseSummaryFailure: false,
        exercises: null,
      };
    case EXERCISE_SUMMARY_SUCCESS:
      return {
        ...state,
        exerciseSummaryRequest: false,
        exerciseSummarySuccess: true,
        exerciseSummaryFailure: false,
        exercises: payload,
      };
    case EXERCISE_SUMMARY_FAILURE:
      return {
        ...state,
        exerciseSummaryRequest: false,
        exerciseSummarySuccess: false,
        exerciseSummaryFailure: true,
      };

    case RECOMMENDED_PRODUCTS_CATEGORIES_REQUEST:
      return {
        ...state,
        recommandedProductCategoryRequest: true,
        recommandedProductCategorySuccess: false,
        recommandedProductCategoryFailure: false,
        recommandedProductCategories: null,
      };
    case RECOMMENDED_PRODUCTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        recommandedProductCategoryRequest: false,
        recommandedProductCategorySuccess: true,
        recommandedProductCategoryFailure: false,
        recommandedProductCategories: payload,
      };
    case RECOMMENDED_PRODUCTS_CATEGORIES_FAILURE:
      return {
        ...state,
        recommandedProductCategoryRequest: false,
        recommandedProductCategorySuccess: false,
        recommandedProductCategoryFailure: true,
        recommandedProductCategories: null,
      };
    default:
      return state;
  }
}

export default programReducer;
